import { render, staticRenderFns } from "./DesignsTab.vue?vue&type=template&id=42b6b121&scoped=true&"
import script from "./DesignsTab.vue?vue&type=script&lang=js&"
export * from "./DesignsTab.vue?vue&type=script&lang=js&"
import style0 from "./DesignsTab.vue?vue&type=style&index=0&id=42b6b121&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42b6b121",
  null
  
)

export default component.exports