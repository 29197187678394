<template>
    <div
        class="card"
        :class="{active: actionsActive, checked: checked}"
        @mouseover="showActions"
        @mouseleave="hideActions">
        <div class="card-header">
            <span
                class="styled-checkbox-checkmark"
                @click="check(designData.id)" />
        </div>
        <div class="card-visual">
            <div
                class="card-image">
                <img
                    :src="designUrl"
                    alt="">
            </div>
            <div class="card-bg" />
            <div class="card-buttons">
                <div class="card-buttons-line">
                    <div class="card-button">
                        <action-button
                            :loading="loadingCreateAd"
                            :light="loadingCreateAd"
                            icon="avt"
                            color="white"
                            size="30"
                            @click="$emit('create-ad', designData)">
                            Create Ad
                        </action-button>
                    </div>
                    <div class="card-button">
                        <action-button
                            icon="eye"
                            color="white"
                            size="30"
                            @click="$emit('show-preview', designData)">
                            Preview
                        </action-button>
                    </div>
                </div>
                <!--<div class="card-buttons-line">
                    <div
                        v-if="showEditButton"
                        class="card-button">
                        <action-button
                            icon="copy"
                            color="white"
                            size="30">
                            Duplicate
                        </action-button>
                    </div>
                    <div
                        v-if="showEditButton"
                        class="card-button">
                        <action-button
                            icon="edit"
                            color="white"
                            size="30">
                            Edit
                        </action-button>
                    </div>
                </div>-->
            </div>
        </div>
        <div class="card-info">
            <div
                ref="title"
                class="card-title">
                <styled-tooltip
                    v-if="titleTooltipActive"
                    position="top"
                    word-break
                    :open-delay="0"
                    :close-delay="0">
                    <template #content>
                        {{ designData.display_name }}
                    </template>
                    {{ title }}
                </styled-tooltip>
                <span v-else>
                    {{ title }}
                </span>
            </div>
            <author-name
                :author="designData.created_by_user_name"
                :author-id="designData.created_by_user_id" />
        </div>
        <div class="card-icons">
            <div class="card-holder">
                <icon
                    v-if="designData.created_by_user_name"
                    name="user"
                    color="#8f9ea6"
                    size="20" />
                <icon
                    v-else
                    name="buyerbridge"
                    color="#8f9ea6"
                    size="20" />
            </div>
            <div class="card-holder">
                <icon
                    :name="designType"
                    color="#8f9ea6"
                    size="20" />
            </div>
        </div>
    </div>
</template>

<script>
import ActionButton from '@/components/globals/ActionButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import AuthorName from '@/components/globals/AuthorName';
import Icon from '@/components/globals/Icon';
import cutTitleLength from '@/helpers/cutTitleLength';
import { BB_USER_ID } from '@/helpers/globals';
import { mapGetters } from 'vuex';

export default {
    components: {
        ActionButton,
        StyledTooltip,
        AuthorName,
        Icon
    },
    props: {
        designData: {
            type: [Object, Array],
            required: true,
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        loadingCreateAd: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            title: '',
            titleTooltipActive: false,
            actionsActive: false,
            checked: false
        };
    },
    computed: {
        ...mapGetters([
            'userIsAdmin',
        ]),
        designType() {
            if (typeof this.designData.asset_type === 'object') {
                return this.designData.asset_type.name;
            }
            return this.designData.asset_type;
        },
        designUrl() {
            return this.designData.url;
        },
        showEditButton() {
            if (this.designData.created_by_user_id == BB_USER_ID && !this.userIsAdmin) {
                return false;
            }
            return true;
        }
    },
    mounted() {
        if (this.isSelected) {
            this.checked = true;
        } else {
            this.checked = false;
        }
        this.title = this.designData.display_name ?? 'untitled';
        this.checkTitleHeight();
    },
    methods: {
        async checkTitleHeight() {
            await this.$nextTick();
            const titleResult = cutTitleLength(this.$refs.title.offsetHeight, this.title);
            if (!titleResult.ok) {
                this.titleTooltipActive = true;
                this.checkTitleHeight();
            }
            this.title = titleResult.title;
        },
        showActions() {
            this.actionsActive = true;
        },
        hideActions() {
            this.actionsActive = false;
        },
        check(id) {
            this.checked = !this.checked;
            if (this.checked) {
                this.$emit('select-design', id);
            } else {
                this.$emit('remove-design', id);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.card {
    width: 29%;
    background: white;
    border: 1px solid #D6DCDF;
    display: inline-block;
    margin-left: 3%;
    margin-top: 3%;
    vertical-align: top;
}
.card.active {
    .card-bg {
        opacity: 1;
    }
    .card-buttons {
        opacity: 1;
    }
}
.card.checked {
    outline: 4px solid #00A4EB;
    border: 1px solid #00A4EB;
    .styled-checkbox-checkmark {
        background-color: $blue-btn;
        border-color: $blue-btn;
        &::after {
            display: block;
        }
    }
}
.card-header {
    height: 30px;
}
.card-visual {
    padding-bottom: 100%;
    position: relative;
}
.card-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4EAEC;
    img, video {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: auto;
    }
}
.card-bg {
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: rgba(0,0,0,0.75);
}
.card-buttons {
    opacity: 0;
    transition: 0.2s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 3;
}
.card-buttons-line {
    display: flex;
    margin: 40px;
}
.card-button {
    margin: 0 40px;
    white-space: nowrap;
}
.card-info {
    padding: 10px 15px;
    border-bottom: 1px solid #D6DCDF;
    font-size: 12px;
    height: 90px;
}
.card-title {
    color: #00A4EB;
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 14px;
    word-wrap: break-word;
}
.card-name {
    color: #00A4EB;
    text-decoration: underline;
}
.card-icons {
    padding: 10px 0;
    display: flex;
    justify-content: flex-start;
}
.card-holder {
    padding: 10px;
    display: flex;
    align-items: center;
    border-right: 1px solid #D6DCDF;
    svg {
        margin: 0 5px;
    }
    &:last-of-type {
        border-right: none;
    }
}
.styled-checkbox-checkmark {
    position: relative;
    display: block;
    top: 5px;
    left: 5px;
    height: 20px;
    width: 20px;
    border-radius: 3px;
    cursor: pointer;
    border: 2px solid;
    border-color: $gray;
    background-color: $white;
    transform-origin: center center;
    transform: scale(var(--checkmark-scale));
    transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
    &::after {
        content: '';
        position: absolute;
        display: none;
        left: 5px;
        top: 1px;
        width: 6px;
        height: 11px;
        border: solid $white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
    &:hover {
        background-color: $alabaster-dark;
    }
}
</style>

