<template>
    <div class="general">
        <!-- PREVIEW POPUP -->
        <preview-popup
            v-if="previewActive"
            :preview-data="designForPreview"
            @close-popup="closePreview" />

        <!-- FILTERS -->
        <div class="filters">
            <div class="filters-top">
                <div class="filters-title">
                    Attributes
                </div>
                <div
                    v-if="activeCustomFilters.length"
                    class="filters-clear"
                    @click="clearAll">
                    Clear all
                </div>
            </div>
            <div
                v-for="customFilter in customFilters"
                :key="customFilter.id"
                class="filters-block">
                <filter-list
                    :filter="customFilter"
                    :loading="loading"
                    :is-custom="true"
                    :active-filters="activeCustomFilters"
                    @select-tag="selectTag" />
            </div>
        </div>
        <!-- END filters -->

        <div class="main">
            <div class="main-top">
                <!-- sorting items -->
                <div class="search-wrapper">
                    <div class="search">
                        <input
                            v-model="searchTerm"
                            type="text"
                            placeholder="Search designs..."
                            @input="onSearch">
                    </div>
                    <div class="sorting">
                        <div class="count">
                            <span>Total Designs: {{ total }}</span>
                        </div>
                        <div class="sort">
                            <span>Sort:</span>
                            <v-select
                                v-model="sort"
                                :items="sortItems"
                                class="adslib__select select-sort"
                                @change="changeSorting" />
                        </div>
                    </div>
                </div>
                <div class="filtered">
                    <div class="library-filters">
                        <div
                            v-if="activeCustomFilters.length"
                            class="library-title">
                            Filtered by:
                        </div>
                        <div class="applied">
                            <div
                                v-for="activeCustomFilter in activeCustomFilters"
                                :key="activeCustomFilter.id"
                                class="single">
                                <span>{{ activeCustomFilter.display_name }}</span>
                                <div @click="removeCustomFilter(activeCustomFilter)">
                                    <icon
                                        name="close"
                                        color="#8f9ea6"
                                        size="10"
                                        @click="removeCustomFilter(activeCustomFilter)" />
                                </div>
                            </div>
                            <div
                                v-if="activeCustomFilters.length"
                                class="clear"
                                @click="clearAll">
                                Clear all
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="selectedDesigns.length"
                        v-outside-click="hideCreation"
                        class="selected">
                        <div
                            class="selected-button"
                            @click="creationActive = true">
                            <div class="selected-circle">
                                {{ selectedDesigns.length }}
                            </div>
                            <span class="mr-3">CREATE ADS</span>
                            <div class="selected-collapse">
                                <icon
                                    name="collapse-all"
                                    color="white"
                                    size="15" />
                            </div>
                        </div>
                        <div
                            v-if="creationActive"
                            class="creation">
                            <div
                                v-for="(item, index) in selectedDesigns"
                                :key="index"
                                class="creation-item">
                                <div class="creation-mediaholder">
                                    <div class="creation-media">
                                        <img
                                            v-if="getAssetType(item) == 'image' || getAssetType(item) == 'design'"
                                            :src="item.url"
                                            alt="">
                                        <video
                                            v-if="item.asset_type == 'video'"
                                            muted
                                            autoplay
                                            loop>
                                            <source
                                                :src="item.url"
                                                type="video/mp4">
                                        </video>
                                    </div>
                                    <div class="creation-desc">
                                        {{ item.display_name }}
                                    </div>
                                </div>
                                <div
                                    class="creation-close"
                                    @click.stop="removeAndUnselect(item.created_at.date)">
                                    <icon
                                        name="close"
                                        color="white"
                                        size="10" />
                                </div>
                            </div>
                            <div
                                class="creation-complete"
                                @click="createMultipleAds">
                                CREATE ADS
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end sorting items -->
            </div>
            <!-- DESIGNS -->
            <div
                :key="cardKey"
                class="card-wrapper">
                <design-card
                    v-for="(design, index) in designs"
                    :key="index"
                    :design-data="design"
                    :is-selected="design.selected"
                    :loading-create-ad="design.id === currentDesignClicked?.id && isLoadingCreateAd"
                    @show-preview="showPreview"
                    @create-ad="handleCreateAd"
                    @select-design="selectDesign"
                    @remove-design="removeDesign" />
            </div>
            <div v-if="loading">
                <loader />
            </div>
            <div
                v-if="!loading"
                v-intersect="getDesigns" />
            <h2
                v-if="!loading && designs.length == 0"
                class="mx-5 my-5">
                Sorry, but nothing matched your search criteria...
            </h2>
            <!-- END DESIGNS -->
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import DesignCard from '@/components/ads-library/DesignCard';
import PreviewPopup from '@/components/ads-library/PreviewPopup';
import Loader from '@/components/globals/Loader.vue';
import FilterList from '@/components/ads-library/FilterList';
import { setParentAssetAsAd, setSelectedPlatformsForAd, setChildAssetsAsAd } from '@/components/creative-studio/layered-designer/utils/asset-to-ad-helper';
import EventBus from '@/event-bus';

import { debounce } from 'lodash';
import { mapState } from 'vuex';

export default {
    components: {
        Icon,
        DesignCard,
        PreviewPopup,
        FilterList,
        Loader
    },
    data() {
        return {
            page: 1,
            searchTerm: '',
            sort: 'Newest',
            sortItems: ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'],
            loading: true,
            customFilters: [],
            activeCustomFilters: [],
            filtersList: [],
            designs: [],
            total: 0,
            cardKey: 0,
            selectedDesigns: [],
            creationActive: false,
            previewActive: false,
            designForPreview: null,
            currentDesignClicked: null,
            isLoadingCreateAd: false,
        };
    },
    computed: {
        ...mapState({
            currentUser: (state) => state.user.user,
            currentAgency: (state) => state.agency.currentAgency,
            currentAgencyId: (state) => state.agency.currentAgency.id,
            isLayeredDesignerOpened: (state) => state.layeredDesigner.isLayeredDesignerActive,
            editedDesignAds: (state) => state.adStudio.editedDesignAds,

        }),

    },
    watch: {
        currentAgencyId() {
            this.reset();
            this.getDesigns();
        },
        activeCustomFilters() {
            this.reset();
            this.getDesigns();
        },
        isLayeredDesignerOpened(isOpen) {
            if(!isOpen) {
                this.reset();
                this.getDesigns();
            }
        }
    },
    async mounted() {
        this.$title = 'Designs Library';
        const rawFilters = [{
            display_name: 'Ownership',
            tags: [
                { display_name: 'My Designs' },
                { display_name: `${this.currentAgency.name} Designs` },
                { display_name: 'BuyerBridge Designs' }
            ]
        }];
        for (const rawFilter of rawFilters) {
            const customFilter = await this.createFilterStructure(rawFilter);
            customFilter.tags.data.forEach(filter => {
                this.filtersList.push(filter);
                this.activeCustomFilters.push(filter);
            });
            this.customFilters.push(customFilter);
        }
        this.loading = false;
        this.getDesigns();
    },
    methods: {
        async getUUID() {
            try {
                const response = await this.$http.get('/uuid/');
                return response.data.uuid4;
            } catch(error) {
                console.log(error);
            }
        },
        async createFilterStructure(filter) {
            const data = [];
            for (const tag of filter.tags) {
                const uuid = await this.getUUID();
                const tagWithUUID = {
                    id: uuid,
                    display_name: tag.display_name
                };
                data.push(tagWithUUID);
            }
            const uuid = await this.getUUID();
            const filterStructure = {
                id: uuid,
                display_name: filter.display_name,
                tags: {
                    data
                }
            };
            return filterStructure;
        },
        changeSorting() {
            this.reset();
            this.getDesigns();
        },
        reset() {
            this.page = 1;
            this.designs = [];
        },
        selectTag(e, isCustom) {
            if (isCustom) {
                const currentFilter = this.filtersList.find(f => f.id == e.target.value);
                if (e.target.checked) {
                    this.activeCustomFilters.push(currentFilter);
                } else {
                    this.removeCustomFilter(currentFilter);
                }
            }
        },
        removeCustomFilter(filter) {
            const filterIndex = this.activeCustomFilters.indexOf(filter);
            if (filterIndex > -1) {
                this.activeCustomFilters.splice(filterIndex, 1);
            }
        },
        clearAll() {
            this.searchTerm = '';
            this.activeCustomFilters = [];
            this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'];
            this.sort = 'Newest';
        },
        onSearch: debounce(function() {
            this.reset();
            // adding relevance when we have something in search field
            if (this.searchTerm != '') {
                this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)', 'Relevance'];
                this.sort = 'Relevance';
            } else {
                this.sortItems = ['Newest', 'Oldest', 'Last Updated', 'Alphabetical (A-Z)', 'Alphabetical (Z-A)'];
                this.sort = 'Newest';
            }
            this.getDesigns();
        }, 500),
        async getDesigns() {
            if (!this.page) {
                return;
            }
            this.loading = true;
            let direction = null;
            let dateField = 'created_at.date.keyword';

            // sorting by date
            if (this.sort == 'Newest') {
                direction = 'DESC';
            } else if (this.sort == 'Oldest') {
                direction = 'ASC';
            } else if (this.sort == 'Last Updated') {
                direction = 'DESC';
                dateField = 'updated_at.date.keyword';
            } else if (this.sort == 'Alphabetical (A-Z)') {
                direction = 'ASC';
                dateField = 'display_name.keyword';
            } else if (this.sort == 'Alphabetical (Z-A)') {
                direction = 'DESC';
                dateField = 'display_name.keyword';
            } else if (this.sort == 'Relevance') {
                direction = 'DESC';
                dateField = '_score';
            }

            // sorting by custom filters
            let userId = null;
            let agencyId = null;
            let ownership = null;
            this.activeCustomFilters.forEach(filter => {
                if (filter.display_name === 'My Designs') {
                    userId = this.currentUser.id;
                }
                if (filter.display_name === `${this.currentAgency.name} Designs`) {
                    agencyId = this.currentAgencyId;
                }
                if (filter.display_name === 'BuyerBridge Designs') {
                    ownership = 'system';
                }
            });

            try {
                const data = {
                    agency_id: this.currentAgencyId,
                    q: this.searchTerm,
                    page_size: 12,
                    page: this.page,
                    asset_types: [5],
                    order: {
                        field: dateField,
                        direction
                    },
                    filter_user_id: userId,
                    filter_agency_id: agencyId,
                    ownership
                };
                const response = await this.$http.post('assets/search', data);
                const responseDesigns = response.data.data;
                responseDesigns.forEach(design => {
                    let designsSelected = this.selectedDesigns.find(selectedDesign => selectedDesign.created_at.date == design.created_at.date);
                    if (designsSelected) {
                        design.selected = true;
                    } else {
                        design.selected = false;
                    }
                });
                this.designs.push(...responseDesigns);
                this.total = response.data.meta.total;
                this.page = response.data.meta.next_page;
            } catch (error) {
                console.error('Error loading creatives', error);
            } finally {
                this.loading = false;
            }
        },
        selectDesign(date) {
            //to do - add ID value to the BACK-END data - date is not very reliable
            const currentDesign = this.designs.find(design => design.created_at.date == date);
            if (currentDesign) {
                currentDesign.selected = true;
            }
            this.selectedDesigns.push(currentDesign);
        },
        removeAndUnselect(date) {
            this.removeDesign(date);
            const currentDesign = this.designs.find(design => design.created_at.date == date);
            if (currentDesign) {
                currentDesign.selected = false;
            }
            this.cardKey += 1;
        },
        removeDesign(date) {
            const currentDesign = this.selectedDesigns.find(design => design.created_at.date == date);
            const designIndex = this.selectedDesigns.indexOf(currentDesign);
            if (designIndex > -1) {
                this.selectedDesigns.splice(designIndex, 1);
            }
        },
        hideCreation() {
            this.creationActive = false;
        },
        showPreview(design) {
            this.previewActive = true;
            this.designForPreview = design;
        },
        closePreview() {
            this.previewActive = false;
        },
         async handleCreateAd(design) {
            if(this.isLoadingCreateAd) {
                return;
            }
            try {
                this.isLoadingCreateAd = true;
                this.currentDesignClicked = design;
                await this.createAd(design);
            } catch (error) {
                console.log(error);
            } finally {
                EventBus.$emit('open-ad-studio', { editMode: true }, { isOpenedFromLayeredDesigner: true });
                this.isLoadingCreateAd = false;
            }

        },
        async createAd(design, index = 0) {
            // In this list the asset_type property is jus a string
            // the ad studio needs the full objects/
            //TODO: fix this in the backend
            const designAssetType = {
                asset_type: {
                    id: 5,
                    name: 'design',
                    display_name: 'Design',
                }
            };

           // TODO: fix this in the backend
             // The asset type is not comming in the response for child assets
            const assetType = {
                        id: 1,
                        name: 'image',
                        display_name: 'Image',
                    };

            Object.assign(design, designAssetType);
            setParentAssetAsAd(this.currentAgencyId, design);
            setSelectedPlatformsForAd(index);

            const response = await this.$http.get(`assets/${design.id}`, {
                    with_relationships: 'assets.children'
                });

                if(!response.data.data.children) {
                    return;
                }

                const childAssets = response.data.data.children.data;

                // Filter for assets that are not an edition of the originals
                const originalChildAssets = childAssets.filter(childAsset => childAsset.configuration?.is_original_asset === true);

                originalChildAssets.forEach(childAsset => {
                    Object.assign(childAsset, { asset_type: assetType });
                });

                setChildAssetsAsAd(index, originalChildAssets);
        },
        async createMultipleAds() {
            try {
                await Promise.allSettled(this.selectedDesigns.map(async(design, index) => {
                    await this.createAd(design, index);
                }));
            } catch (error) {
                console.log(error);
            } finally {
                EventBus.$emit('open-ad-studio', { editMode: true }, { isOpenedFromLayeredDesigner: true });
            }
        },
        getAssetType(item) {
            if(typeof item.asset_type === 'object') {
                return item.asset_type.name;
            }
            return item.asset_type;
        },
    }
};
</script>

<style lang="scss" scoped>
.general {
    display: flex;
}
.main {
    background: #F7F8FC;
    width: 80%;
    min-height: 68vh;
}
.main-top {
    position: relative;
}
.search-wrapper {
    padding: 15px 30px;
    padding-right: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.search {
    position: relative;
    min-width: 50%;
    padding-right: 4%;
    input {
        width: 100%;
        background: white;
        border: 1px solid #DEDEDE;
        border-radius: 5px;
        line-height: 38px;
        outline: none;
        padding-left: 55px;
    }
    &:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 18px;
        left: 18px;
        top: calc(50% - 9px);
        background-image: url('/img/search.svg');
        pointer-events: none;
    }
}
.sorting {
    display: flex;
    align-items: center;
    height: 37px;
}
.select-sort {
    width: 110px;
}
.count {
    border-right: 1px solid #D6DCDF;
    padding: 0 20px;
    height: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.sort {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 20px;
}
.filtered {
    padding: 0 30px;
    border-bottom: 1px solid #D5DBDE;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.library-filters {
    padding-right: 45px;
}
.library-title {
    color: #00A4EB;
    margin-bottom: 5px;
}
.applied {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
}
.single {
    display: flex;
    align-items: center;
    background: #D7F0FB;
    border-radius: 25px;
    padding: 5px 15px;
    margin-right: 15px;
    margin-bottom: 5px;
    span {
        margin-right: 15px;
    }
    div {
        display: flex;
        align-items: center;
        margin-top: 2px;
        cursor: pointer;
    }
}
.clear {
    color: #00A4EB;
    text-decoration: underline;
    cursor: pointer;
}
.selected {
    position: relative;
    display: flex;
    align-items: center;
}
.selected-button {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    padding: 10px;
    padding-right: 20px;
    border-radius: 5px;
    background: #00A4EB;
    cursor: pointer;
    vertical-align: top;
    & > span{
        color:white;
        font-weight: bold;
    }
}
.selected-collapse {
    transform: rotate(180deg) translateY(-3px);
}
.selected-circle {
    background: white;
    color: #00A4EB;
    font-size: 20px;
    font-weight: 700;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-right: 15px;
}
.selected-icon {
    width: 30px;
    height: 30px;
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}
.creation {
    position: absolute;
    z-index: 4;
    background: white;
    padding: 15px;
    width: 428px;
    right: 0;
    top: calc(100% + 10px);
    box-shadow: 0 0 6px 4px rgba(0,0,0,0.1);
    border-radius: 5px;
    &:after{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: white;
        box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.1);
        top: -10px;
        right: 15px;
        transform: rotate(45deg);
        z-index: 99;
    }
    &:before{
        content: '';
        position: absolute;
        width: 60px;
        height: 20px;
        background: white;
        top: 0;
        right: 0;
        z-index: 100;
    }
}
.creation-item {
    background: #F7F8FC;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;
    color: #00A4EB;
    font-size: 16px;
    margin-bottom: 15px;
}
.creation-mediaholder {
    display: flex;
    align-items: center;
}
.creation-media {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    img,video {
        max-width: 100%;
        max-height: 100%;
    }
}
.creation-desc {
    overflow: hidden;
    max-width: 250px;
    white-space: nowrap;
}
.creation-close {
    display: flex;
    border-radius: 50%;
    background: #909FA8;
    padding: 5px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 15px;
}
.creation-complete {
    display: table;
    margin: 0 auto;
    text-align: center;
    color: white;
    font-weight: 700;
    cursor: pointer;
    font-size: 18px;
    padding: 8px 45px;
    border-radius: 5px;
    background: #00A4EB;
}

// SIDE FILTERS
.filters {
    width: 20%;
    padding: 15px 20px;
}
.filters-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.filters-title {
    color: #00A4EB;
}
.filters-clear {
    color: #00A4EB;
    text-decoration: underline;
    cursor: pointer;
}
.filters-block {
    margin-bottom: 30px;
    padding-left: 20px;
}
</style>
